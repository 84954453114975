// custom typefaces
// import "typeface-montserrat";
// import "typeface-merriweather";

import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js";
import "prismjs/themes/prism-tomorrow.css";
// import "./src/styles/prism-custom.scss";
import "./src/styles/prism-override.scss";

import "./src/styles/global.scss";
